.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 80%; /* Adjust as needed */
    max-width: 80vw;
  }
  
  .logoImage {
    max-height: 100%;
    max-width: 80vw;
    margin: 0 20px; /* Space between logo and text */
  }
  