.linkTreeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.1); 
    }
  }
  