.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.gridItem {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(107, 107, 107, 0.37);
  backdrop-filter: blur(6px); /* Just for effect */
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 200ms ease;
  height: 300px;
}


.gridItem::before {
  content: "";
  position: absolute;
  height: 150%; /* Ensure it covers the height regardless of size */
  width: 60px; /* Slightly wider for visibility */
  transform: rotate(30deg);
  background-color: rgba(255, 255, 255, 0.2);
  top: -100px; /* Adjust for positioning */
  left: -150%; /* Ensure it starts well off-screen */
  opacity: 0; /* Initially hidden */
}

.gridItem:hover::before {
  animation: shine 1s forwards; /* Play animation forwards only */
}

@keyframes shine {
  0% {
    left: -150%;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    left: 150%;
    opacity: 0;
  }
}

.imageContainer {
  width: 100%;
  height: 100%; /* Ensure the container height adjusts */
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0; /* Initially hidden */
  visibility: hidden;
  transition: opacity 0.7s ease, visibility 0.7s ease, transform 0.7s ease; /* Smooth fade */
  .modalContent {
    transform: translateY(10%); /* Initially hidden */
    transition: transform 0.7s ease; /* Smooth slide */
  }
}

.showModal {
  opacity: 1;
  visibility: visible;
  .modalContent {
    transform: translateY(0%);
  }
}

.hideModal {
  opacity: 0;
  visibility: hidden;
  .modalContent {
    transform: translateY(10%);
  }
}

.modalContent {
  position: relative;
  width: 100%; /* Full width */
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  overflow: hidden;
}

.fullImage {
  max-width: 100vw; /* Limit image to 90% of the viewport width */
  max-height: 100vh; /* Limit image to 90% of the viewport height */
  object-fit: contain; /* Ensure the image is fully contained within the modal */
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 1001;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.closeButton:hover {
  color: rgb(203, 169, 253);
}
